import React from 'react'
import HeadingBox from './HeadingBox'
import ServiceBox from './ServiceBox'
import { ourServices } from "../utils/constants"

const Services = () => {
  return (
    <section className='flex flex-col bg-[#FFE6D2] lg:px-10 px-4 py-20 gap-10'>
        <div>
            <HeadingBox 
                subtitle="Our Services"
                desc="Contact us today to explore how our IT solutions can revolutionize your organization. Together, let's unlock the full potential of your business with Ventures Business Group"
                title="We build software solutions that solve client's business challenges"
                button="Start a Project"
                btnLink="enquiry"
                classN="customWidth"
            />
        </div>
        <div className='grid lg:grid-cols-3 md:grid-cols-2 gap-5'>
          {
            ourServices.map((item) => (
              <ServiceBox title={item.title} desc={item.description} itemId={item.id} key={item.title} btnTitle="show"/>
            ))
          }
        </div>
    </section>
  )
}

export default Services