import { BackToTop, Footer, HeadingBox, Nav, ServiceBox, TitleBox } from '../../components'
import React from 'react'
import {wifi, wifiService } from '../../utils/constants'
const WiFi = () => {
  let even = []
  even = ['502','504','506','508','510']
  const numb = even.map((item) => item)
  const classes = "lg:px-10 px-4 py-12 flex lg:flex-row flex-col gap-5 justify-between"
  const evenClass = "lg:px-10 px-4 py-12 bg-[#37006718] flex lg:flex-row-reverse flex-col gap-5 justify-between"
  return (
    <>
    <Nav />
    <main>
        <section className="lg:py-10 py-7 lg:px-10 px-4 bg-[#FFE6D2] flex lg:flex-row flex-col  justify-between">
        <div>
          <HeadingBox
              title="Wi-Fi"
              desc="Wi-Fi, also known as Wireless Fidelity, has become an essential part of our daily lives, enabling wireless connectivity for various devices. At Ventures BIZ, we specialize in designing and deploying reliable and secure Wi-Fi solutions for businesses. Our comprehensive Wi-Fi services include:"
          />
        </div>
        </section >

        <section className="lg:py-5 py-2 lg:px-10 px-4 bg-[#FFE6D2] flex lg:flex-row flex-col  justify-between">
          <div className='grid lg:grid-cols-3 md:grid-cols-2 gap-5'>
            {
              wifiService.map((item) => (
                <ServiceBox title={item.title} desc={item.description} itemId={item.id} key={item.title}/>
              ))
            }
          </div>
        </section>
        {
          wifi.map((item) => (
            <section className={even.includes(item.id) ? evenClass : classes} key={item.id}>
        <div className="flex items-center">
          <TitleBox 
            title={item.title}
            desc={item.description}
          />
          </div>
            <div className="flex lg:w-[500px] w-full lg:h-[300px] h-[250px] relative">
                <img src={item.image} alt="Feature"   className="h-full object-cover" /> 
            </div>
      </section>
          ))
        }
    </main>
    <BackToTop />
    <Footer />
    </>
  )
}

export default WiFi