import React, {Component} from 'react'
import Slider from 'react-slick'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { partners } from '../utils/constants';
export default class SliderComponent extends Component{
  render(){
    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 2,
      className:"w-full",
      initialSlide: 0,
      autoplay: true,
      speed: 5000,
      autoplaySpeed: 5000,
      cssEase: "linear",
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: true,
            dots: false,
            className:"mx-10"
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            initialSlide: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            className:"mx-10"
          }
        }
      ]
    };

    return(
      <div className='flex flex-col' >
      <Slider {...settings}>
        {partners.map((item) => (
              <div className='relative' key={item}>
                <img 
                  src={item} 
                  alt="slides" 
                  width={186}
                  height={105}
                  className='object-cover'
                />
              </div>
          ))
          }
      </Slider>
    </div>
    )
  }
}