
const TopAniBar = () => {
  return (
    <>
<div className="absolute flex flex-row top-0 left-0 w-[90%] h-[80%]">
            <div className='h-full w-[30%] bg-[#FFFFFF66]'></div>
            <div className='absolute h-[70%] w-[70%] top-0 left-0 bg-[#FFCC01]' ></div>
            <div className='absolute right-0 top-0 h-[30%] w-[60%] bg-[#FFFFFF66]'></div>
        </div>
    </>
  )
}

export default TopAniBar