import React from 'react'
import { HeadingBox, TopAni, Barani, Partners, Expertise, Nav, BackToTop, Footer } from '../../components'
import { aboutStats } from "../../utils/constants"
import { access, cloud, dese, parking } from '../../assets'
const Company = () => {
  return (
    <>
        <Nav />
      <main>
      <section className="lg:py-20 py-12 lg:px-10 px-4 flex flex-row justify-between">
        <div className='flex flex-col gap-3'>
          <div className='bg-custom-yellow w-[28px] h-[28px]'></div>
          <HeadingBox 
            title="Behind the Scenes: Unveiling Ventures BIZ's Secret to Success." 
            subtitle="OUR COMPANY"
            desc="Get an exclusive glimpse into the inner workings of Ventures Business and Investment Limited. Discover the core values, exceptional talent, and relentless passion that drive our success in delivering top-notch IT solutions. "
          />
        </div>
        <div>
          <TopAni />
        </div>
      </section>
      
      <section className="py-5 lg:px-10 px-4">
        <div className="flex-between w-full lg:flex-row flex-col gap-5">
                  <div className="lg:w-[33%] w-full lg:h-[446px] h-[300px] relative">
                      <img src={cloud}  className="h-full object-cover" alt="about image"/>
                  </div>
                  <div className="lg:w-[33%] w-full lg:h-[446px] h-[300px] relative">
                      <img src={access}  className="h-full object-cover" alt="about image"/>
                  </div>
                  <div className="lg:w-[33%] w-full lg:h-[446px] h-[300px] relative ">
                      <img src={parking}  className="h-full object-cover" alt="about image"/>
                      <div className="absolute mt-[-8px] right-0 top-0 h-[40px] w-[80%]">
                        <Barani />
                      </div>
                  </div>
                  
        </div>
      </section>

      <section className='lg:px-10 px-4 py-10 flex lg:flex-row flex-col gap-5 justify-between'>
        <div className='flex flex-col gap-4'>
          <div className='w-7 h-7 bg-custom-bg'></div>
          <HeadingBox 
            title="Transforming Businesses Through Innovation"
            subtitle="Our Story"
            desc="Discover the captivating story behind Ventures Business and Investment Limited's journey to becoming a leading force in the IT solutions industry. From humble beginnings to revolutionizing businesses with our innovative services, join us as we share our story of growth, resilience, and unwavering commitment to empowering organizations. Unleash the potential of your business by exploring our inspiring journey of transformation."
          />
        </div>
        <div className='bg-[#FFD3AF91] flex items-center justify-center p-4'>
          <div className="grid grid-cols-2 justify-between items-start gap-10 ">
                  {
                      aboutStats.map((item) => (
                          <div className="relative flex flex-col gap-[8px]" key={item}>
                              <h2 className="2xl:text-[32px] sm:text-[24px] text-[206x] font-bold">{item.number}</h2>
                              <div className="w-[80%]">
                                  <Barani />
                              </div>
                              <h6 className="text-base lg:max-w-[200px] max-w-[270px]">{item.title}</h6>
                          </div>
                      ))
                  }
              </div>
        </div>
      </section>

      <section className='py-5'>
        <Partners />
      </section>

      <Expertise />

      <section className='lg:px-10 px-4 py-15 flex flex-col justify-between gap-5'>
        <HeadingBox 
          title="Revolutionizing the IT Landscape: Unveiling Ventures Business and Investment Limited's Vision"
          subtitle="OUR VISION"
          desc="Our vision is to be the leading provider of innovative IT solutions that empower businesses to thrive in the digital era. We strive to revolutionize the way organizations operate, helping them leverage technology to drive growth, efficiency, and success. "
        />
        <div className='w-full h-[450px] relative'>
          <img src={dese} fill alt="Vision" className='object-cover' />
        </div>
      </section>
    </main>
    <BackToTop />
    <Footer /> 
    </>
  )
}

export default Company