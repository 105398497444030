import { Link } from "react-router-dom"

const Widget = ({WidgetTitle, links}) => {
  return (
    <div className='flex flex-col gap-2'>
        <h2 className='text-[#232536] font-semibold text-lg'>{WidgetTitle}</h2>
        <div className='flex flex-col gap-2 '>
            {links.map((link, id) => (
                <Link className='text-[#232536] font-medium text-sm capitalize' href={`/${link}`} key={id}>{link}</Link>
            ))}
        </div>
    </div>
  )
}

export default Widget