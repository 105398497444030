import React, { useRef } from 'react'
import { AniBar, BackToTop, Barani, CustomButton, Footer, FormElement, HeadingBox, Nav, NormalButton, PopupModal } from '../../components'
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import emailjs from '@emailjs/browser';
const Contact = () => {
  const [user_name, setName] = useState("");
  const [user_email, setEmail] = useState("")
  const [user_subject, setSubject] = useState("")
  const [user_message, setMessage] = useState("")
  const [sent, setSent] = useState(false)
  const [loading, setLoading] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const form = useRef()
  const {
    reset,
    control, 
    handleSubmit, 
    formState:{errors, isSubmitSuccessful}
  } = useForm({
    defaultValues: {
      user_name: "",
      user_email:"",
      user_subject:"",
      user_message: "",
    }
  })

  useEffect(() => {
    if(isSubmitSuccessful){
      reset();
    }
  }, [isSubmitSuccessful, reset])

  
  const onSubmit = async(data) => {
    setLoading(true)
    console.log(data)
    try {
            const response = emailjs.sendForm(
              "service_0n0xjp9",
              "template_7dgxxsw",
              form.current,
              "6-C9C3xK9hqcYOJ56",
            ).then(
              (result) => {
                setShowModal(true);
                setLoading(false)
                reset()
              },
              (error) => {
                setLoading(false)
                console.log(error.text);
                alert("Failed....", error)
              }
            )
          } catch (error) {
            console.error("Error sending email", error);
            setLoading(false)
          }
  }
  const handleCloseModal = () => {
    setShowModal(false);
  }
  return (
    <>
    <Nav />
    <main>
      <section className='lg:px-10 px-4 py-20 flex lg:flex-row flex-col justify-between gap-5'>
        <div className='flex flex-col gap-4'>
          <div className='w-[28px] h-[28px] bg-custom-yellow'></div>
          <HeadingBox 
            title="Have a Question ?
            Let’s Get in Touch with us 👋"
            desc="Fill up the form and our team will get back to you within 24hrs"
          />
          <form onSubmit={handleSubmit(onSubmit)} ref={form} className='flex flex-col gap-5 p-4 border border-[#ECE7F1]'>
            <Controller
              name='user_name'
              control={control}
              rules={{required: true}}
              render={({field}) => (
                <FormElement 
                  type="text" 
                  label="Name" 
                  placeholder="Enter your name here...." 
                  fieldRef={field} 
                  hasError={errors.name?.type === 'required'}
                />
              )}
            />
            <Controller
              name='user_email'
              control={control}
              rules={{required: true}}
              render={({field}) => (
                <FormElement 
                  type="text" 
                  label="Email" 
                  placeholder="Enter your Email here...." 
                  fieldRef={field} 
                  hasError={errors.name?.email === 'required'}
                />
              )}
            />
            <Controller
              name='user_subject'
              control={control}
              rules={{required: true}}
              render={({field}) => (
                <FormElement 
                  type="text" 
                  label="Subject" 
                  placeholder="Enter your Subject here...." 
                  fieldRef={field} 
                  hasError={errors.subject?.type === 'required'}
                />
              )}
            />
            <Controller
              name='user_message'
              control={control}
              rules={{required: true}}
              render={({field}) => (
                <FormElement 
                  type="textarea" 
                  label="Message" 
                  placeholder="Enter your Message here...." 
                  fieldRef={field} 
                  hasError={errors.message?.type === 'required'}
                />
              )}
            />
            {
                loading ? <p className='bg-pink-300 text-black rounded-lg p-2'>Loading.....</p> : <NormalButton title="Send Message" type="submit" />

              }
          </form>
          {
            showModal && (
              <PopupModal 
                message="Message sent succesfully"
                onClose={handleCloseModal}
                autoCloseDuration={15000}
              />
            )
          }
        </div>
        <div className='relative'>  
          <div className="w-[30%] absolute right-0">
            <Barani />
          </div>
          <div className='bg-[#ECE7F1] flex flex-col gap-4 lg:px-28 px-10 py-12'>
              <div className='flex flex-col gap-3'>
                <h6 className='text-sm font-medium text-black opacity-60' >Location</h6>
                <div className='h-[1px] w-[100%] bg-black opacity-80'></div>
                <div>
                  <h2 className='text-lg font-medium text-black max-w-[264px]'>
                    Plot 10, 43 Chris Madueke Dr, Lekki Phase 1, 106104, Lagos.
                  </h2>
                </div>
              </div>
              <div className='flex flex-col gap-3'>
                <h6 className='text-sm font-medium text-black opacity-60' >Working Hours</h6>
                <div className='h-[1px] w-[100%] bg-black opacity-80'></div>
                <div>
                  <h2 className='text-lg font-medium text-black max-w-[264px]'>
                    Monday To Friday <br/>
                    9:00AM to 8:00PM
                  </h2>
                  <h6 className='text-sm font-medium text-black opacity-60' >Our Support Team is available 24hrs</h6>
                </div>
                
              </div>
              <div className='flex flex-col gap-3'>
                <h6 className='text-sm font-medium text-black opacity-60' >Contact Us</h6>
                <div className='h-[1px] w-[100%] bg-black opacity-80'></div>
                <div>
                  <h2 className='text-lg font-medium text-black max-w-[264px]'>
                    sales@venturesbiz.com.ng
                  </h2>
                  <h6 className='text-sm font-medium text-black opacity-60' >info@venturesbiz.com.ng</h6>
                </div>
              </div>
          </div>
          <div className="absolute bottom-0 left-0 h-[103px] w-[24px]">
                <AniBar />
          </div>        
        </div>
      </section>
    </main>
    <BackToTop />
    <Footer />
    </>
  )
}

export default Contact