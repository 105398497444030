import React from 'react'
import CustomButton from './CustomButton'

const TitleBox = ({subtitle, title, desc, button, classN, subtitleText}) => {
  return (
    <div className="flex flex-col gap-4">
            <h6 className={`lg:text-sm text-xs ${subtitleText}`}>{subtitle}</h6>
            <h2 className={`lg:max-w-[622px] max-w-[80%] lg:text-4xl text-lg font-semibold ${classN}`}>{title}</h2>
            <p className="lg:text-base text-sm lg:max-w-[622px] max-w-[100%]">{desc}</p>
            {
                button && <CustomButton title={button} />
            }
    </div>
  )
}

export default TitleBox