import { avaya, emc, zoho, ricoh, yabatech, sweet, sos, lifecare, frigo, desicon, artworld, agronigeria, leventis, cyberone, cybertwo, cyberthree, cyberfive, cybersix, wifione, wifitwo, wifithree, wififour, wififive, wifisix, wifiseven, cloudthree, cloudfour, itone, itfour, cloudone, cloudtwo, commone, commtwo, commthree, commfour, itthree, ittwo } from "../assets"
export const partners = [
    avaya,
    emc,
    zoho,
    ricoh,
    yabatech,
    sweet,
    sos,
    lifecare,
    frigo,
    desicon,
    artworld,
    agronigeria,
    leventis
]

export const aboutStats = [
    {
        "number": "100+",
                "title": "Successful Implementations of Managed IT Solutions"
    },
    {
        "number": "50+",
        "title": "Managed Cloud Service"
    },
    {
        "number": "100+",
        "title": "Deployed CCTV Locations"
    },
    {
        "number": "10 yrs",
        "title": "Experience"
    },
    
]
export const expertiseContent = [
    {
        "icon": "htp",
        "title": "On Time Delivery",
        "description": "By prioritizing on-time delivery, we ensure that our clients can rely on us to deliver their IT solutions promptly, minimizing disruptions and maximizing the value they receive."
    },
    {
        "icon": "htp",
        "title": "Reliability",
        "description": "We are committed to delivering reliable and stable IT solutions that you can count on. Our proactive monitoring and round-the-clock support ensure that your systems are up and running without disruptions."
    },
    {
        "icon": "htp",
        "title": "Customization",
        "description": "We understand that every business has unique requirements. We tailor our solutions to meet your specific needs, ensuring that you get the most value out of our services"
    }
]

export const unifiedCommunication = [
    {        
        "id":"101",
        "title": "Contact Center Solutions",
        "description": "Our comprehensive omnichannel solution, gives you the insights and tools you need to keep them happy and coming back. Use real-time 360º data to fuel smarter decision-making, enhance agent-customer interactions, and drive loyalty. Optimize operations with seamless system and workflow integrations and cultivate new opportunities with prepackaged and customized options. Choose the deployment option that best suits your needs—on premises, in the cloud, or hybrid.",
        "image": commone
    },
    {        
        "id":"102",
        "title": "On Premise PBX",
        "description": "Our IP Telephony system completely replaces analog PBX systems or provides the ability to run a hybrid system as a halfway point to full IP Telephony. It supports standard SIP soft/hard phones and myriad VoIP services. It eliminates the traditional wired phone network required for legacy phone systems and its web-based administration makes phone system management easy. Handsets located in remote offices or homes of key executives abroad to your office PBX as local extensions.",
        "image": commtwo
    },
    {        
        "id":"103",
        "title": "Cloud PBX",
        "description": "For businesses with an on-premise IP Telephony system thinking about migrating to IP Office in the cloud, or if you have just made the decision to move to a IP phone system. We provide seamless IP telephony/voice, and video and mobile applications. You can switch to scalable, per-user, OPEX-based pricing, lower TCO, and technology modernization. Choose the right solution for each employee and pay for only what you need, when you need it.",
        "image": commthree
    },
    {        
        "id":"104",
        "title": "Video Conferencing ",
        "description": "Business at the speed of the Internet can be daunting to companies of any size. And when you’re trying to figure out what technologies to adopt and allocate budget for, there is a host of different variables to consider. With today’s sales teams, who are often virtual or remote, there’s a real need to bring people together on a regular basis in an efficient, productive and cost-effective manner. Netcom offers and array of solutions based upon your company’s needs.",
        "image": commfour
    },

]
export const managedItSolution = [
    {        
        "id":"201",
        "title": "IT Outsourcing",
        "description": "Designed to reduce your costs, increase your productivity and mitigate your business risks, we partner with you as your IT Department, allowing you to focus on running your business, not your technology.Give us a chance to fill your ICT aptitudes holes and take away the problem that accompanies selecting. We trust that ICT administrations ought to be about organization and sharing duty",
        "image": itone
    },
    {        
        "id":"202",
        "title": "IT Project Management",
        "description": "Every IT project is driven by a business requirement; the hard part is translating that requirement into a product that meets the business need. The right technology can help your business make the best use of information and resources, empowering you to execute at your functional and strategic best. But successful technology involves much more than selecting a system",
        "image": ittwo
    },
    {        
        "id":"203",
        "title": "Data Center",
        "description": "Our facilities offer secure power and connectivity that leverage on our diverse network of providers to ensure your data operations remain uninterrupted. We provide the most highly available and efficient generator-backed power systems so you will have the peace of mind knowing your servers can be up and running, and accessible.",
        "image": itthree
    },
    {        
        "id":"204",
        "title": "Application Development",
        "description": "Every business requirement is unique and there cannot possibly be one software that solves them all. This must be why millions of users around the world have built custom apps for their various needs. Netcom provides essential tools and all the help you need to build custom apps, all by yourself, regardless of your technical expertise.",
        "image":itfour
    },

]
export const managedCloudService = [
    {        
        "id":"301",
        "title": "Office 365",
        "description": "Office 365 is a cloud-based subscription service that brings together the best tools for the way people work today. By combining best-in-class apps with powerful cloud services, Office 365 lets anyone create and collaborate anywhere on any device.",
        "image": cloudone
    },
    {        
        "id":"302",
        "title": "Cloud server Colocation ",
        "description": "Reduced capital expenditures, greater flexibility and the virtualization of processes are all benefits of running your business on cloud servers. We will assess your cloud readiness, analyze your applications, review your environment, and harden your security to ensure your cloud strategy works as planned.",
        "image": cloudtwo
    },
    {        
        "id":"303",
        "title": "Backup and Recovery",
        "description": "Netcom’s eVault uses some of the newest priority in technology to reduce, automate & encrypt your backups in order to decrease the amount of manpower & hardware currently being expended on current backup processes. All data is backed up at the bit level to shorten the backup times & reduce the backup footprint.",
        "image": cloudthree
    },
    {        
        "id":"304",
        "title": "ERP",
        "description": "Dynamics, zoho, odoo – Enterprise resource planning (ERP) software standardizes, streamlines, and integrates business processes across finance, human resources, procurement, distribution, sales, and other departments. Choosing an ERP system is among the most challenging decisions facing IT leaders.",
        "image": cloudfour
    },

]
export const cyberSecurity = [
    {        
        "id":"401",
        "title": "Network Security",
        "description": "Advanced attacks are more coordinated than ever before. Now, your defenses are too. We’ve partnered with Sophos, the global leader in endpoint protection. Sophos Endpoint blocks malware and infections by identifying and preventing the handful of techniques and behaviors used in almost every exploit.",
        "image": cyberone
    },
    {        
        "id":"402",
        "title": "Web Security ",
        "description": "Get the ultimate in web security, control, and insights. It’s web protection that’s effective, affordable, and easy with lightning performance that won’t slow users down.",
        "image": cybertwo
    },
    {        
        "id":"403",
        "title": "Email Security",
        "description": "Attackers use email in over 90% of breaches. Defend your business against ransomware, phishing, and other sophisticated attacks.",
        "image": cyberthree
    },
    {        
        "id":"404",
        "title": "Identity & Access Management",
        "description": "Security is best when it’s silent, our solutions feature both web and on-premise SSO, multi-factor authentication, user provisioning with self-service and access governance, password synchronization, self-service password reset and even AD & NTFS auditing tools.",
        "image": cybertwo
    },
    {        
        "id":"405",
        "title": "Next Generation Firewall",
        "description": "Managed Next Gen Firewall – Unrivaled Security, Simplicity, and Insight. Sophos XG Firewall provides unprecedented visibility into your network, users, and applications directly from the all-new control center. Our industry leading protection blocks the latest advanced threats like ransomware, bots, worms, hacks, breaches and APTs.",
        "image": cyberfive
    },
    {        
        "id":"406",
        "title": "DDOS Protection",
        "description": "DDoS attacks are caused by thousands of globally distributed internet-based ‘bots’* flooding target websites with such a high volume of traffic that genuine users can no longer visit. Netcom has partnered with Tata Communications to deliver the solution over a cloud-based infrastructure. Our award-winning service scrutinizes your network traffic in real time to identify anomalies and quarantine attack packets.",
        "image": cybersix
    },

]
export const wifi = [
    {        
        "id":"501",
        "title": "Wi-Fi Planning and Design",
        "description": "Our expert team conducts a thorough assessment of your business requirements and premises to design a Wi-Fi network that meets your specific needs. We consider factors such as coverage area, user density, and application requirements to create a robust and scalable Wi-Fi infrastructure.",
        "image": wifione
    },
    {        
        "id":"502",
        "title": "Wi-Fi Site Surveys ",
        "description": "We perform detailed site surveys to evaluate the RF (Radio Frequency) environment, identify potential sources of interference, and determine optimal access point placement. This ensures optimal coverage, signal strength, and performance throughout your premises.",
        "image": wifitwo
    },
    {        
        "id":"503",
        "title": "Access Point Installation and Configuration",
        "description": "We install and configure Wi-Fi access points based on the design and site survey findings. Our technicians ensure proper placement, cabling, and configuration of access points to deliver optimal coverage, seamless roaming, and reliable connectivity",
        "image":wifithree
    },
    {        
        "id":"504",
        "title": "Network Security",
        "description": "We prioritize network security in our Wi-Fi solutions. Our team implements industry-standard security protocols, such as WPA2-Enterprise, to protect your network from unauthorized access. We also offer advanced security features like guest network isolation and client device authentication to ensure secure and controlled access.",
        "image": wififour
    },
    {        
        "id":"505",
        "title": "Network Performance Optimization",
        "description": "We optimize Wi-Fi network performance by implementing techniques such as channel planning, load balancing, and Quality of Service (QoS) configuration. This ensures consistent and reliable connectivity, even in high-density environments, while prioritizing critical applications and minimizing network congestion",
        "image": wififive
    },
    {        
        "id":"506",
        "title": "Wi-Fi Management and Monitoring",
        "description": "We provide centralized management and monitoring tools that allow you to efficiently manage and monitor your Wi-Fi network. This includes features like network analytics, device management, and proactive monitoring to ensure optimal performance, troubleshoot issues, and make informed network management decisions..",
        "image": wifisix
    },
    {        
        "id":"507",
        "title": "Guest Wi-Fi and Captive Portal",
        "description": "We enable guest Wi-Fi access with captive portal solutions, allowing you to provide secure and controlled internet access to visitors. You can customize the captive portal with your branding and set up authentication methods, user policies, and content filtering for a seamless and secure guest experience.",
        "image": wifiseven
    },
    {        
        "id":"508",
        "title": "Wi-Fi Expansion and Scalability",
        "description": "Our Wi-Fi solutions are designed with scalability in mind. As your business grows or as your Wi-Fi needs evolve, we can seamlessly expand your network, add access points, and optimize performance to accommodate increased user demand and ensure uninterrupted connectivity.",
        "image": wifitwo
    },

]
export const wan = [
    {        
        "id":"601",
        "title": "WAN Design and Architecture",
        "description": "Our expert team assesses your business requirements and designs a customized WAN architecture to meet your specific needs. We consider factors such as bandwidth requirements, network redundancy, security, and scalability to create a robust and efficient WAN infrastructure.",
        "image": wififive
    },
    {        
        "id":"602",
        "title": "MPLS and SD-WAN Deployment ",
        "description": "We deploy Multiprotocol Label Switching (MPLS) or Software-Defined Wide Area Network (SD-WAN) solutions, depending on your requirements. MPLS offers secure and reliable connectivity with Quality of Service (QoS) guarantees, while SD-WAN provides agility, cost optimization, and enhanced application performance.",
        "image": cloudthree
    },
    {        
        "id":"603",
        "title": "Network Optimization and Performance Enhancement",
        "description": "We optimize your WAN to maximize network performance and ensure efficient data transmission. Our team employs various techniques such as traffic prioritization, data compression, bandwidth management, and Quality of Service (QoS) configuration to enhance network performance and user experience.",
        "image": cloudfour
    },
    {        
        "id":"604",
        "title": "Secure Connectivity",
        "description": "Security is a top priority for WAN solutions. We implement robust security measures, including encryption, firewall configuration, intrusion detection and prevention systems, and secure VPN (Virtual Private Network) connections to safeguard your data and ensure secure connectivity across the WAN.",
        "image": wifithree
    },
    {        
        "id":"605",
        "title": "Centralized Network Management",
        "description": "Our WAN solutions enable centralized network management, allowing you to efficiently monitor and control your network from a single location. We provide network monitoring tools, analytics, and reporting capabilities, enabling proactive management, troubleshooting, and optimization of your WAN infrastructure.",
        "image": wifiseven
    },
    {        
        "id":"606",
        "title": "WAN Optimization",
        "description": "We employ WAN optimization techniques to enhance network efficiency and reduce latency. By implementing caching, compression, and deduplication technologies, we improve data transfer speeds, reduce bandwidth utilization, and enhance overall WAN performance.",
        "image": itone
    },
    {        
        "id":"607",
        "title": "Hybrid WAN Integration",
        "description": "We seamlessly integrate hybrid WAN solutions, combining the benefits of MPLS, internet connectivity, and cloud-based services. This integration allows you to optimize costs, increase agility, and leverage the scalability and flexibility of cloud resources while maintaining secure and reliable connectivity.",
        "image": itfour
    },
    {        
        "id":"608",
        "title": "Reliable & Secure",
        "description": "Our WAN solutions empower your organization with reliable, secure, and efficient connectivity across geographically dispersed locations. Whether you have multiple branches, remote offices, or data centers, our expertise in WAN design, deployment, optimization, and security ensures a robust and future-proof network infrastructure. Experience seamless connectivity and enhanced collaboration with our comprehensive WAN services.",
        "image":cybertwo
    },

]

export const ourServices = [
    {
        "id": "121",
        "icon": "http",
        "title": 'Wide Area Network',
        "description": "It's a network infrastructure that connects geographically dispersed locations, such as offices, branches, or data centers. It enables the seamless transmission of data, voice, and video across long distances. "
    },
    {
        "id": "122",
        "icon": "http",
        "title": "Managed Cloud Service",
        "description": "Whether you require public, private, or hybrid cloud environments, we design, deploy, and manage scalable and secure cloud architectures. Experience the benefits of flexibility, scalability, and cost efficiency as we seamlessly migrate your applications and data to the cloud."
    },
    {
        "id": "123",
        "icon": "http",
        "title": "Managed IT Solutions",
        "description": "From network management and system monitoring to helpdesk support and software updates, we ensure the smooth functioning of your IT systems, allowing you to focus on your core business operations."
    },
    {
        "id": "124",
        "icon": "http",
        "title": "Unified Communication",
        "description": " We integrate voice, video, messaging, and conferencing tools into a single, seamless platform, enabling real-time communication and improving productivity across teams and locations"
    },
    {
        "id": "125",
        "icon": "http",
        "title": "Cyber Security",
        "description": "We implement a multi-layered security approach, including firewalls, encryption, intrusion detection systems, and vulnerability assessments. With continuous monitoring and proactive threat detection, we ensure your networks, data, and systems are protected"
    },
    {
        "id": "126",
        "icon": "http",
        "title": "Wi-Fi",
        "description": "Wi-Fi, also known as Wireless Fidelity, has become an essential part of our daily lives, enabling wireless connectivity for various devices. We specialize in designing and deploying reliable and secure Wi-Fi solutions for businesses."
    },
]
export const wifiService = [
    {
        "id": "221",
        "icon": "http",
        "title": '3G/LTE APN Internet',
        "description": "A Dual SIM 3G/LTE service / router can be used in to provide temporary internet links, for mobile applications, kiosks, or emergency services. This can also be used for backup to critical services andc locations "
    },
    {
        "id": "222",
        "icon": "http",
        "title": "VSAT Internet Services",
        "description": "For very remote locations where there’re is no Fibre / Radio service or on special request, a Ku-band, Ka-band or C-band VSAT internet service can be provided. This VSAT internet service can also be used as backup to highly critical services, particularly at remote locations."
    },
    {
        "id": "223",
        "icon": "http",
        "title": "Estate and Community Wifi / Fibre Internet Services",
        "description": "This is an estate / office pack based service that will provide very affordable internet access to home users and Small Office / Home Offices. These internet service hotspots will primarily target Cyber Cafes, Restaurants, Malls, Parks, Residential Estates and office plazas/blocks – and the primary access is via Wifi, or Estate Fibre loops."
    },
    // {
    //     "id": "224",
    //     "icon": "http",
    //     "title": "Transit Wifi Internet Services",
    //     "description": " We provide Wifi internet access for various transit and passenger transport systems like, Railways, bus trasits, taxis etc. These Wifi service is provded in partnership with the transit service company, and can be either free, and finance via captive portal adverts or is a paid service or a mixture of both. These transit Wifi systems can also be use to provide dynamic digital signage and advertising."
    // },
    // {
    //     "id": "225",
    //     "icon": "http",
    //     "title": "Cyber Security",
    //     "description": "We implement a multi-layered security approach, including firewalls, encryption, intrusion detection systems, and vulnerability assessments. With continuous monitoring and proactive threat detection, we ensure your networks, data, and systems are protected"
    // },
    // {
    //     "id": "226",
    //     "icon": "http",
    //     "title": "Wi-Fi",
    //     "description": "Wi-Fi, also known as Wireless Fidelity, has become an essential part of our daily lives, enabling wireless connectivity for various devices. We specialize in designing and deploying reliable and secure Wi-Fi solutions for businesses."
    // },
]
export const wanService = [
    {
        "id": "321",
        "icon": "http",
        "title": 'Satellite and BGAN WAN links',
        "description": "A Microwave point to point or point to multipoint radio CPE will be utilised to link from the fibre termination point our local NOC to the clients office. Generally, a telecoms mast of appropriate height is needed for this radio installation. In circumstance and locations where metro fibre exists or can be provided, then fibre can be used as last mile to the client’s premises."
    },
    {
        "id": "322",
        "icon": "http",
        "title": "Dual 3G / LTE Private APN Links",
        "description": "We also provides 3G/4G mobile services based Internet and WAN solutions with dual GSM provider redundancy. This service can be used as backup to fibre based WAN or Internet services, and is also used to provide primary link services to ATM, Kiosks, Gaming centers, and small office branches."
    },
    {
        "id": "323",
        "icon": "http",
        "title": "Automatic Failover Service and Router",
        "description": "A router configured for automatic failover between the optional 2 or more links as above t can be installed at the clients location(s) to automatically failover to the redundant link in the case of the primary fibre/radio internet link failure."
    },
    // {
    //     "id": "224",
    //     "icon": "http",
    //     "title": "Transit Wifi Internet Services",
    //     "description": " We provide Wifi internet access for various transit and passenger transport systems like, Railways, bus trasits, taxis etc. These Wifi service is provded in partnership with the transit service company, and can be either free, and finance via captive portal adverts or is a paid service or a mixture of both. These transit Wifi systems can also be use to provide dynamic digital signage and advertising."
    // },
    // {
    //     "id": "225",
    //     "icon": "http",
    //     "title": "Cyber Security",
    //     "description": "We implement a multi-layered security approach, including firewalls, encryption, intrusion detection systems, and vulnerability assessments. With continuous monitoring and proactive threat detection, we ensure your networks, data, and systems are protected"
    // },
    // {
    //     "id": "226",
    //     "icon": "http",
    //     "title": "Wi-Fi",
    //     "description": "Wi-Fi, also known as Wireless Fidelity, has become an essential part of our daily lives, enabling wireless connectivity for various devices. We specialize in designing and deploying reliable and secure Wi-Fi solutions for businesses."
    // },
]

export const ourProcess = [
    {
        "icon": "http",
        "number": "01",
        "title": "Discovery and Assessment",
        "description": "We begin by understanding your unique business requirements, challenges, and goals. This in-depth analysis enables us to develop tailored solutions that address your specific needs",
        "bottom": "show"
    },
    {
        "icon": "http",
        "number": "02",
        "title": "Solution Design and Planning",
        "description": "Based on the analysis, our experienced team designs a customized IT solution that aligns with your goals. Our focus is on delivering scalable, cost-effective, and future-proof solutions."
    },
    {
        "icon": "http",
        "number": "03",
        "title": "Implementation and Deployment",
        "description": "Once the solution is approved, our skilled technicians and project managers execute the implementation plan. We prioritize minimal disruption to your business operations during the implementation phase."
    },
    {
        "icon": "http",
        "number": "04",
        "title": "Testing and Quality Assurance",
        "description": "Rigorous testing and quality assurance measures are conducted to ensure that the implemented solution meets the highest standards of performance, reliability, and security, ensuring a robust and reliable IT infrastructure."
    },
    {
        "icon": "http",
        "number": "05",
        "title": "Training and Knowledge Transfer",
        "description": "Our experts offer hands-on training, user guides, and ongoing support that provides comprehensive training sessions to your staff, equipping them with the knowledge and skills required to utilize the solution effectively. "
    },
    {
        "icon": "http",
        "number": "06",
        "title": "Ongoing Support and Maintenance",
        "description": "Our team of dedicated professionals provides timely support, proactive monitoring, regular system updates, and 24/7 assistance to ensure the continuous performance and security of your IT solutions.",
        "bottom": "show"
    },
]

export const footerLinks = [
    {
        "quickLink" : ['Home', 'Company','Career','News'],
        "serviceLink" :  ['ext', 'Testing','Development','AWS/Azure','Consulting','Information Technology'],
        "resourceLink" : ["About Us", 'Testimonial', 'Privacy Policy', 'Terms of use', 'Blog']
    }
]

export const AboutPage = [
    "We primarily provide hardware, software, services and maintenance most especially for companies who require the benefit of a skilled team of professionals to manage their Technology.. assets without the high cost of building an IT department",
    "Businesses will find it more strategic to outsource their IT to a firm which provides long term continuity, rather than entrust it to individuals who can leave at anytime and who may possess all the knowledge about the IT.. assets",
    "Such an exit would leave a company crippled, since there mostly is no defined procedure for transition & no documentation with which they can train replacements.",
    "We take away all this by providing different, cl1early defined levels of support required by different firms taking into cognizance their different goals, size and existing Technology.. assets.",
    "We go further to draw and implement plans geared towards bringing technology to meet constantly changing business goals and objectives .We assist in research, deployment and training, working with major software, hardware vendors and other bigger IT firms all in order to ensure our clients get the best."
]

export const services = [
    {
        id: 1,
        name: "WAN"
    },
    {
        id: 2,
        name: "Managed IT Solutions"
    },
    {
        id: 3,
        name: "Unified Communication"
    },
    {
        id: 4,
        name: "Managed Cloud Service"
    },
    {
        id: 5,
        name: "Cyber Security"
    },
    {
        id: 6,
        name: "Biometric Access Control"
    },
    {
        id: 7,
        name: "CCTV"
    },
    {
        id: 8,
        name: "Parking Automation"
    },
    {
        id: 9,
        name: "Alternating Power Supply"
    },
    {
        id: 10,
        name: "UPS, Computers and Servers"
    },
]
export const products = [
    "Managed IT Solutions",
    "Unified Communication",
    "Managed Cloud Services",
    "IT Security",
    "EDMS",
    "Biometric Access Control",
    "CCTV",
    "Parking Automation",
    "Alternating Power Supply",
    "UPS, Computers and Servers"
]

export const WebsiteSettings = {
    "name": "Ventures Business Group",
    "Email": "te",
    "Phone": "09",
    "logo": "ht"
}