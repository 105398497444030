import { AniBar, Barani } from "."
import { cyber } from "../assets"
import { power, unified } from "../assets"
import { aboutStats } from "../utils/constants"
import { Link } from "react-router-dom"
const About = () => {
  return (
    <section className="lg:px-10 px-4 py-10 flex-between flex-col gap-10 bg-[#37006718]">
        <div className="flex-between flex-col gap-10">
            <div className="flex-between gap-[10px] lg:flex-row flex-col ">
                <div className="lg:w-[60%] w-full flex flex-col gap-[10px]">
                    <p className="text-base font-medium">ABOUT US</p>
                    <h2 className="lg:text-5xl text-xl text-[#232536] font-semibold lg:max-w-xl max-w-[100%]">Empowering Businesses with Seamless IT Solutions</h2>
                </div>
                <div className="lg:w-[40%] w-full flex flex-col gap-[10px]">
                    <h3 className="max-w-xl font-semibold text-3xl lg:block hidden">The Era of Technology</h3>
                    <p className="lg:text-base text-sm font-medium">We believe that successful IT solutions are not just about implementing the latest technologies but also understanding the unique challenges and aspirations of each business we serve. That's why we take a personalized approach to every project, collaborating closely with our clients to develop tailored solutions that align with their goals and unlock their full potential. </p>
                </div>
            </div>
            <div className="flex-between w-full lg:flex-row flex-col gap-5">
                <div className="lg:w-[33%] w-full lg:h-[446px] h-[300px] relative">
                    <img src={power} className="h-full object-cover" alt="about image"/>
                </div>
                <div className="lg:w-[33%] w-full lg:h-[446px] h-[300px] relative">
                    <img src={cyber} className="h-full object-cover" alt="about image"/>
                </div>
                <div className="lg:w-[33%] w-full lg:h-[446px] h-[300px] relative">
                    <img src={unified} className="h-full object-cover" alt="about image"/>
                    <div className="absolute right-0 top-0 h-[100%] w-[18px]">
                    <AniBar />
                    </div>
                </div>
                
            </div>
        </div>

        <div className="flex-between w-full md:flex-row flex-col gap-5">
            <div className="grid lg:grid-cols-4 grid-cols-2 justify-between items-start gap-10 ">
                {
                    aboutStats.map((item) => (
                        <div className="relative flex flex-col gap-[8px]" key={item.title}>
                            <h2 className="2xl:text-[32px] sm:text-[24px] text-[206x] font-bold">{item.number}</h2>
                            <div className="w-[80%]">
                                <Barani />
                            </div>
                            <h6 className="text-base lg:max-w-[200px] max-w-[270px]">{item.title}</h6>
                        </div>
                    ))
                }
            </div>
            <button className="clone about flex items-center gap-1">
                <Link className="flex items-center gap-1" href="/company">
                <span>Read More About Us</span>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3" />
                </svg>
                </Link>
                
                </button>
        </div>

    </section>
  )
}

export default About