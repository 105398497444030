import React from 'react'
import { BackToTop, Footer, Nav, Newsletter } from "../../components";

import { About, Expertise, Hero, Partners, Process, Services } from '../../components'
const Home = () => {
  return (
    <>
    <Nav />
    <main className="">
      <Hero />
      <Partners />
      <About />
      <Expertise />
      <Services />
      <Process />
    </main> 
    <BackToTop />
    <Footer /> 
    </>
    
  )
}

export default Home