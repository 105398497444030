import { useState, useEffect } from "react"
import {FaArrowUp} from 'react-icons/fa';

const BackToTop = () => {
    const [isVisisble, setIsVisisble] = useState(false)

    const toggleVisibility = () => {
        if(window.pageYOffset > 300){
            setIsVisisble(true);
        }else{
            setIsVisisble(false)
        }
    };

    const scrollToUp = () => {
        window.scrollTo({
            top:0,
            behavior: 'smooth'
        })
    }
    
    useEffect(() => {
      window.addEventListener('scroll', toggleVisibility);
    
      return () => {
        window.removeEventListener('scroll', toggleVisibility)
      }
    }, [])
    
  return (
    <div>
        {
            isVisisble && (
                <button className="fixed bottom-5 right-5 bg-[#333] text-white border-none rounded-full w-10 h-10 flex justify-center items-center text-lg cursor-pointer opacity-70 transition-opacity hover:opacity-100 z-10" onClick={scrollToUp}>
                    <FaArrowUp />
                </button>
            )
        }
    </div>
  )
}

export default BackToTop