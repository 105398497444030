import React from 'react'
import { BackToTop, Footer, Process, TitleBox, HeadingBox, Nav } from '../../components'
import {products} from "../../utils/constants"
import { access, cctv, cloud, cyber, edms, it, parking, power, unified } from '../../assets'
const Services = () => {
  return (
    <>
    <Nav />
    <main>
      <section className="lg:py-20 py-12 lg:px-10 px-4 bg-[#FFE6D2] flex lg:flex-row flex-col justify-between">
        <div>
          <HeadingBox 
            title="Unleash the Power of Transformation: Discover Our Game-Changing Services" 
            subtitle="OUR SERVICES"
            desc=" Revolutionize your business with our comprehensive suite of game-changing IT services. we have the expertise and cutting-edge technology to take your organization to new heights."
            button="Request A Quote"
            btnLink="request"
          />
        </div>
        <div className="px-10 text-xl font-semibold lg:flex hidden flex-col gap-3">
          {products.map((item) => (
            <h4>{item}</h4>
          ))}
        </div>
      </section>
      <Process />
      <section className="lg:px-10 px-4 py-12 flex lg:flex-row flex-col gap-5 justify-between">
        <div className="flex">
          <TitleBox 
            subtitle="Managed IT Solutions" 
            title="Streamlined IT Operations for Uninterrupted Business Growth"
            desc="Our Managed IT Solutions offer comprehensive support to optimize your IT operations. Our team of experts takes care of your network management, infrastructure maintenance, software updates, and helpdesk support, ensuring your systems run smoothly. With proactive monitoring, we identify and address issues before they impact your business, providing you with reliable support and ensuring optimal performance. "
          />
          </div>
            
            <div className="flex lg:w-[500px] w-full lg:h-[300px] h-[250px] relative">
                <img src={it} alt="Feature" fill  className="object-cover" /> 
            </div>
      </section>
      <section id="communication" className="lg:px-10 px-4 py-12 flex lg:flex-row flex-col gap-5 justify-between bg-[#37006718]">
        <div className="flex lg:w-[500px] w-full lg:h-[300px] h-[250px] relative">
          <img src={unified} alt="Feature" fill className="object-cover" /> 
        </div>
        <div className="flex">
          <TitleBox 
            subtitle="Unified Communication" 
            title="Connect, Communicate, and Collaborate in Real Time for or Enhanced Productivity and Efficiency"
            desc="Our Unified Communication solutions bring your organization's communication channels together on a single platform. Voice, video, messaging, and conferencing tools seamlessly integrate, enabling real-time collaboration across teams and locations. Experience enhanced productivity, improved information exchange, and streamlined communication channels. "
          />
          </div>
            
      </section>
      <section id="cloud" className="lg:px-10 px-4 py-12 flex lg:flex-row flex-col gap-5 justify-between">
        <div className="flex">
          <TitleBox 
            subtitle="Cloud Services" 
            title="Tailored Cloud Environments for Agility and Cost Efficiency"
            desc="Our Cloud Services offer customized cloud solutions designed to meet your specific business needs. Whether you require public, private, or hybrid cloud environments, we design, deploy, and manage scalable and secure cloud architectures. Experience the benefits of flexibility, scalability, and cost efficiency as we seamlessly migrate your applications and data to the cloud."
          />
          </div>
            
            <div className="flex lg:w-[500px] w-full lg:h-[300px] h-[250px] relative">
    
                <img src={cloud} alt="Feature" fill className="object-cover" /> 
                </div>
      </section>
      <section id="cyber" className="lg:px-10 px-4 py-12 flex lg:flex-row flex-col gap-5 justify-between bg-[#37006718]">
      
      <div className="flex lg:w-[500px] w-full lg:h-[300px] h-[250px] relative">
          <img src={cyber} alt="Feature" fill className="object-cover" /> 
          </div>
        <div className="flex">
          <TitleBox 
            subtitle="Cyber Security" 
            title="Comprehensive Protection Against Evolving Cyber Threats"
            desc="Our IT Security solutions safeguard your organization's imgal../.. assets from ever-evolving cyber threats. We implement a multi-layered security approach, including firewalls, encryption, intrusion detection systems, and vulnerability assessments. With continuous monitoring and proactive threat detection, we ensure your networks, data, and systems are protected"
          />
        </div>
      </section>
      <section id="cctv" className="lg:px-10 px-4 py-12 flex lg:flex-row flex-col gap-5 justify-between">
        <div className="flex">
          <TitleBox 
            subtitle="CCTV" 
            title="High-Definition Cameras and Advanced Video Analytics for Enhanced Security"
            desc="Our CCTV solutions offer comprehensive surveillance for your business premises. We deploy high-definition cameras, advanced video analytics, and remote monitoring capabilities to deter criminal activities, provide valuable insights, and maintain a secure environment."
          />
          </div>
            
            <div className="flex lg:w-[500px] w-full lg:h-[300px] h-[250px] relative">
    
                <img src={cctv} alt="Feature" fill className="object-cover" /> 
                </div>
      </section>
      <section id="biometric" className="lg:px-10 px-4 py-12 flex lg:flex-row flex-col gap-5 justify-between bg-[#37006718]">
      
      <div className="flex lg:w-[500px] w-full lg:h-[300px] h-[250px] relative">
          <img src={access} alt="Feature" fill className="object-cover" /> 
          </div>
        <div className="flex">
          <TitleBox 
            subtitle="Biometric Access Control" 
            title="Secure and Convenient Access for Authorized Personnel"
            desc="Our Biometric Access Control solutions provide advanced security measures to protect your premises. Leveraging technologies such as fingerprint, facial recognition, and iris recognition, we ensure secure and convenient access for authorized personnel. Say goodbye to traditional access methods and embrace biometric solutions that offer enhanced security and eliminate the risks associated with lost or stolen access credentials."
          />
        </div>
      </section>
      <section id="parking" className="lg:px-10 px-4 py-12 flex lg:flex-row flex-col gap-5 justify-between">
        <div className="flex">
          <TitleBox 
            subtitle="Parking Automation" 
            title="Optimize Parking Management for Efficiency and Customer Experience"
            desc="Our Parking Automation solutions simplify and optimize parking management. From ticketing systems and parking guidance to license plate recognition and payment integration, we help you maximize parking efficiency, improve customer experience, and increase revenue. Streamline your parking operations with our smart parking automation solutions."
          />
          </div>
            
            <div className="flex lg:w-[500px] w-full lg:h-[300px] h-[250px] relative">
    
                <img src={parking} alt="Feature" fill className="object-cover" /> 
                </div>
      </section>
      <section id="edms" className="lg:px-10 px-4 py-12 flex lg:flex-row flex-col gap-5 justify-between bg-[#37006718]">
      
      <div className="flex lg:w-[500px] w-full lg:h-[300px] h-[250px] relative">
          <img src={edms} alt="Feature" fill className="object-cover" /> 
          </div>
        <div className="flex">
          <TitleBox 
            subtitle="EDMS (Electronic Document Management System)" 
            title="Digitize, Automate, and Centralize Your Document Workflows"
            desc=" Our EDMS solution transforms your document management processes for increased efficiency. We digitize your documents, automate workflows, and provide secure storage, version control, and easy retrieval. Say goodbye to manual paper-based processes and embrace a digital document ecosystem that enhances collaboration and reduces costs. "
          />
        </div>
      </section>
      <section id="power" className="lg:px-10 px-4 py-12 flex lg:flex-row flex-col gap-5 justify-between">
        <div className="flex">
          <TitleBox 
            subtitle="Alternating Power Supply" 
            title="Our Alternating Power Supply solutions provide reliable backup power for your critical operations."
            desc="We offer a range of backup power systems, including generators, UPS (Uninterruptible Power Supply), and energy storage solutions. With our expertise, minimize downtime and protect your business from power outages, ensuring continuous operations"
          />
          </div>
            
            <div className="flex lg:w-[500px] w-full lg:h-[300px] h-[250px] relative">
    
                <img src={power} alt="Feature" fill className="object-cover" /> 
                </div>
      </section>
    </main>
    <BackToTop />
    <Footer />
    </>
  )
}

export default Services