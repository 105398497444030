import { 
    BrowserRouter,
    Routes,
    Route 
} from "react-router-dom"
import Home from "./pages/home/Home"
import Services from "./pages/services/Services"
import Company from "./pages/company/Company"
import Contact from "./pages/contact/Contact"
import Request from "./pages/request/Request"
import Enquiry from "./pages/enquiry/Enquiry"
import ManagedCloud from "./pages/services/ManagedCloud"
import WideAreaNetwork from "./pages/services/WideAreaNetwork"
import WiFi from "./pages/services/WiFi"
import ManagedSolution from "./pages/services/ManagedSolution"
import UnifiedCommunication from "./pages/services/UnifiedCommunication"
import CyberSecurity from "./pages/services/CyberSecurity"
const App = () => {
  return (
    <BrowserRouter>
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/services" element={<Services />} />
            <Route path="/services/wi-fi" element={<WiFi />} />
            <Route path="/services/wide_area_network" element={<WideAreaNetwork />} />
            <Route path="/services/managed_cloud_service" element={<ManagedCloud />} />
            <Route path="/services/managed_it_solutions" element={<ManagedSolution />} />
            <Route path="/services/unified_communication" element={<UnifiedCommunication />} />
            <Route path="/services/cyber_security" element={<CyberSecurity />} />
            <Route path="/company" element={<Company />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/enquiry" element={<Enquiry />} />
            <Route path="/request" element={<Request />} />
        </Routes>
    </BrowserRouter>
  )
}

export default App