
const Barani = () => {
    return (
      <>
  <div className='flex flex-row bg-red-900 w-full h-[8px]'>
              <div className='bg-[#FFCC01] w-[20%]'></div>
              <div className='bg-[#370067] w-[50%]'></div>
              <div className='w-[30%] bg-[#FFD3AF]'></div>
          </div>
      </>
    )
  }
  
  export default Barani