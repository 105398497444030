import TopAniBar  from "./TopAniBar"
import {motion} from "framer-motion";
import {hover, tap, xitem } from "../utils/variants";
import { Link } from "react-router-dom";
const CustomButton = ({title, type, routeLink}) => {
  return (
    <Link to={`/${routeLink}`}>
       <motion.button type={type || "submit"} whileHover={hover}
    whileTap={tap} variants={xitem} initial="hidden" animate="visible" className="relative mt-4 bg-[#370067] w-[200px] h-[60px] flex gap-2 items-center justify-center text-base text-white">
        <div className="w-[28px] h-[28px] absolute top-0 left-0">
              <TopAniBar />
            </div>
        {title}
        <motion.svg 
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-4 h-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"
              />
            </motion.svg>
    </motion.button>
    </Link>
   
  )
}

export default CustomButton