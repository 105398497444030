import { useState } from "react"

import { Link } from "react-router-dom";
import { logo } from "../assets";


const Nav = () => {
  const [mobileMenu, setMobileMenu] = useState(false)
  const inactiveLink = "navLink text-[#ababab]"
  const activeLink = 'navLink text-white active'
  const pathname = window.location.pathname;

  const Menu = () => (
    <>
        <Link to ="/" onClick={() => setMobileMenu(false)} className={pathname === '/' ? activeLink : inactiveLink}>Home</Link>
        <Link to ="/company" onClick={() => setMobileMenu(false)} className={pathname.includes('/company') ? activeLink : inactiveLink}>Company</Link>
        <Link to ="/services" onClick={() => setMobileMenu(false)} className={pathname.includes('/services') ? activeLink : inactiveLink}>Services</Link>
        <Link to ="/contact" onClick={() => setMobileMenu(false)} className={pathname.includes('/contact') ? activeLink : inactiveLink}>Contact Us</Link>
          <button onClick={() => setMobileMenu(false)} className="clone flex items-center gap-1">
            <Link to ="/enquiry" className="flex items-center gap-1">
            <span>Make Enquiries</span>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3" />
            </svg>
            </Link> 
          </button>
        

    </>

  )
  return (
    <nav className="h-[80px] bg-[#370067] flex flex-row justify-between lg:px-10 px-4 border-b-1 border-[#ababab]">
      <div className="flex items-center">
        <Link to="/">
            <img src={logo} alt="logo" className="object-contain w-[100px] h-[100px]" />
        </Link>
      </div>

      {/* desktop navigation       */}
      <div className="md:flex hidden gap-5 text-base font-medium">
        <Menu />
      </div>

      <div className="md:hidden flex items-center text-white">
        <div className="cursor-pointer">
          {
            mobileMenu ? (
              <div onClick={() => setMobileMenu(false)}>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                </svg>
              </div>
            ):(
              <div onClick={() => setMobileMenu(true)}>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-7 h-7">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
                              </svg>
              </div>
            )
          }
        </div>
        {
          mobileMenu&&
          <div className="absolute top-0 mt-20 z-10 border-t-2 border-[#FFCC01] bg-[#4a107c] right-0 px-8 py-8 flex flex-col gap-5">
            <Menu />
          </div>
        }
      </div>
    </nav>
  )
}

export default Nav