const AniBar = () => {
  return (
    <>
<div className='flex flex-col absolute right-0 bg-red-900 w-[100%] h-full'>
            <div className='bg-[#FFCC01] h-[20%]'></div>
            <div className='bg-[#FFD3AF] h-[50%]'></div>
            <div className='h-[30%] bg-[#370067]'></div>
        </div>
    </>
  )
}

export default AniBar