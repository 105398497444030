import Widget from './Widget'
import {BsFacebook, BsLinkedin, BsInstagram, BsTwitter} from 'react-icons/bs'
import TwoWidget from './TwoWidget'
import { logo } from '../assets'
const Footer = () => {
    const quicj = ['company','services','contact']
    const serviceLink = ['cyber security', 'managed it solutions','managed cloud service']
    const resourceLink = ["wide area network", 'unified communication ', 'wi-fi']
  return (
    <>
    <div className='lg:px-10 px-4 py-20 flex justify-between relative md:flex-row flex-col bg-[#ECE7F1] gap-10'>
        <div className=' flex flex-col gap-3'>
            <h2 className='lg:text-5xl text-2xl font-semibold text-[#232536] md:max-w-lg max-w-sm'>Let's make <br /> something special</h2>
            <h3 className='font-semibold lg:text-2xl text-lg text-[#232536] flex items-center gap-2 '>
                <span>Let's talk!</span>
                <span className='text-[#FFA155]'>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M6.633 10.5c.806 0 1.533-.446 2.031-1.08a9.041 9.041 0 012.861-2.4c.723-.384 1.35-.956 1.653-1.715a4.498 4.498 0 00.322-1.672V3a.75.75 0 01.75-.75A2.25 2.25 0 0116.5 4.5c0 1.152-.26 2.243-.723 3.218-.266.558.107 1.282.725 1.282h3.126c1.026 0 1.945.694 2.054 1.715.045.422.068.85.068 1.285a11.95 11.95 0 01-2.649 7.521c-.388.482-.987.729-1.605.729H13.48c-.483 0-.964-.078-1.423-.23l-3.114-1.04a4.501 4.501 0 00-1.423-.23H5.904M14.25 9h2.25M5.904 18.75c.083.205.173.405.27.602.197.4-.078.898-.523.898h-.908c-.889 0-1.713-.518-1.972-1.368a12 12 0 01-.521-3.507c0-1.553.295-3.036.831-4.398C3.387 10.203 4.167 9.75 5 9.75h1.053c.472 0 .745.556.5.96a8.958 8.958 0 00-1.302 4.665c0 1.194.232 2.333.654 3.375z" />
                    </svg>
                    </span>
            </h3>
            <div className='text-[#232536] font-medium text-lg'>
                <h6>sales@venturesbiz.com.ng</h6>
            </div>
            <div className='text-[#232536] text-sm font-medium mt-3 border-t border-[#232536] pt-3'>
                <address>Plot 10, 43 Chris Madueke Road, Lekki Phase 1. 106104, Lagos</address>
            </div>
        </div>
        <div className='flex flex-row lg:gap-20 gap-5 justify-between'>
            <Widget WidgetTitle="Quick Links" links={quicj} />
            <TwoWidget WidgetTitle="Service" links={serviceLink} prefix="/services" />
            <TwoWidget WidgetTitle="Resources" links={resourceLink} prefix="/services" />
        </div>
    </div>


    <div className='px-5 lg:px-10 py-5 flex sm:flex-row flex-col items-center bg-[#370067] justify-between gap-4'>
        <div className='flex sm:flex-row flex-col sm:gap-4 gap-2 items-center'>
            <img src={logo} alt='FooterLogo' width={60} height={24} className='object-cover' />
            <h5 className='text-white font-medium text-base'>© 2023 Ventures Business and Investment Limited</h5>
        </div>
        <div className='flex flexx-row gap-4 text-base text-white'>
            <BsFacebook />
            <BsTwitter />
            <BsInstagram />
            <BsLinkedin />
        </div>
    </div>
    </>
    
  )
}

export default Footer