import { item, yitem } from '../utils/variants'
import {motion} from "framer-motion"
import { partners } from '../utils/constants'
import SliderComponent from './Slider'
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
const Partners = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows:true,
      };
  return (
    <div className='bg-[#37006718] w-full lg:px-10 px-4 py-5 flex lg:flex-row flex-col lg:items-center items-start text-[#370067 justify-between'>
        <div className='lg:w-[20%] w-[100%]'>
            <motion.h6
                variants={item}
                initial="hidden"
                animate="visible"
            >
                Our Clients
            </motion.h6>
            <motion.h2
                variants={item}
                initial="hidden"
                animate="visible"
                className="font-medium text-lg"
            >
                We've Worked with
            </motion.h2>
        </div>
        <motion.div className="lg:w-[80%] w-[100%]" variants={yitem} initial="hidden" animate="visible">
        <SliderComponent />
        </motion.div>
    </div>
  )
}

export default Partners