'use client'

import { useEffect, useState } from "react"
import {motion} from 'framer-motion';

const PopupModal = ({message, onClose, autoCloseDuration}) => {
    const [isOpen, setIsOpen] = useState(false)
    useEffect(() => {
      setIsOpen(true);
      const timer = setTimeout(() => {
        setIsOpen(false);
        onClose();
      }, autoCloseDuration)
    
      return () => clearTimeout(timer);
    }, [autoCloseDuration, onClose])
    
  return (
    <motion.div
        className={`fixed z-10 right-0 top-0 p-5 w-64 bg-[#37006718] shadow-md transform transition-transform duration-300 ${isOpen ? 'translate-x-0' : 'translate-x-full'}`}
    >
        <div className="p-4">
            <p>{message}</p>
        </div>
        <button className="w-full py-2 bg-custom-bg text-white font-bold uppercase text-sm" onClick={onClose}>
            Close
        </button>
    </motion.div>
  )
}

export default PopupModal