
const TopAni = () => {
  return (
    <div className='lg:w-[50px] w-[30px] lg:h-[50px] h-[30px] flex flex-row relative'>
                    <div className='w-[30%] h-[30%] bg-[#37006718]'></div>
                    <div className='w-[70%] h-[70%] bg-[#370067]'></div>
                    <div className='w-[40%] h-[70%]  absolute right-0 top-[30%] flex flex-col'>
                        <div className='w-[100%] h-[50%] bg-[#FFCC01]'></div>
                        <div className='w-[100%] h-[50%] bg-[#FFD3AF]'></div>
                    </div>
                </div>
  )
}

export default TopAni