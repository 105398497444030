import { BackToTop, Footer, HeadingBox, Nav, TitleBox } from '../../components'
import { managedCloudService } from '../../utils/constants'
const ManagedCloud = () => {
  let even = []
  even = ['302','304','306','308','310']
  const numb = even.map((item) => item)
  const classes = "lg:px-10 px-4 py-12 flex lg:flex-row flex-col gap-5 justify-between"
  const evenClass = "lg:px-10 px-4 py-12 bg-[#37006718] flex lg:flex-row-reverse flex-col gap-5 justify-between"
  return (
    <>
    <Nav />
    <main>
        <section className="lg:py-20 py-12 lg:px-10 px-4 bg-[#FFE6D2] flex lg:flex-row flex-col  justify-between">
        <div>
          <HeadingBox
              title="Managed Cloud Service"
              subtitle=""
              desc="Whether you require public, private, or hybrid cloud environments, we design, deploy, and manage scalable and secure cloud architectures."
          />
        </div>
        </section>
        {
          managedCloudService.map((item) => (
            <section className={even.includes(item.id) ? evenClass : classes} key={item.id}>
        <div className="flex items-center">
          <TitleBox 
            title={item.title}
            desc={item.description}
          />
          </div>
            <div className="flex lg:w-[500px] w-full lg:h-[300px] h-[250px] relative">
                <img src={item.image} alt="Feature" className="h-full object-cover" /> 
            </div>
      </section>
          ))
        }
    </main>
    <BackToTop />
    <Footer />
    </>
  )
}

export default ManagedCloud