import { useRef, useState } from 'react'
import { services } from '../../utils/constants'
import { BackToTop, Footer, FormElement, HeadingBox, Nav, NormalButton, PopupModal } from '../../components'
import emailjs from '@emailjs/browser';

const Enquiry = () => {
  const [user_name, setName] = useState('');
  const [user_email, setEmail] = useState('');
  const [user_phone, setPhone] = useState('');
  const [user_company, setCompany] = useState('');
  const [selectedServices, setSelectedServices] = useState([]);
  const [serviceDetails, setServiceDetails] = useState({});
  const [sent, setSent] = useState(false)
  const [loading, setLoading] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const form = useRef()

  const classes = "form-control w-full px-3 py-1.5 text-gray-700 rounded border border-solid border-gray-300 focus:border-pink-600 focus:outline-none"

  const handleServiceSelection = (e) => {
    const serviceName = e.target.value;
    if(e.target.checked){
      const newSelectedServices = [...selectedServices, serviceName];
      setSelectedServices(newSelectedServices);
    }else{
      const newSelectedServices = selectedServices.filter(
        (service) => service !== serviceName
      );
      setSelectedServices(newSelectedServices);      
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true)
    const formData = {
      user_name,
      user_email,
      user_phone,
      user_company,
      user_services: selectedServices.map((service) => ({
        name: service,
      })),
    };
    console.log(formData)
    try {
      const response = emailjs.sendForm(
        "service_0n0xjp9",
        "template_lt8te4u",
        form.current,
        "6-C9C3xK9hqcYOJ56",
      ).then(
        (result) => {
          setShowModal(true);
          setLoading(false)
          setName('');
          setEmail('');
          setPhone('');
          setCompany('');
          setSelectedServices([]);
        },
        (error) => {
          setLoading(false)
          console.log(error.text);
          alert("Failed....", error)
        }
      )
    } catch (error) {
      console.error("Error sending email", error);
      setLoading(false)
    }
}

  const handleCloseModal = () => {
    setShowModal(false);
  }
  return (
    <>
    <Nav />
    <main>
      <section className='px-10 py-20 flex gap-5 flex-col'>
        <div className='flex flex-col gap-4'>
            <div className='w-[28px] h-[28px] bg-custom-yellow'></div>
            <HeadingBox 
              title="Enquiry"
              desc="Fill up the form and our team will get back to you within 24hrs"
            />
        </div>
        <div>
          <form onSubmit={handleSubmit} ref={form} action="" className='flex flex-col gap-5 p-4 border border-[#ECE7F1]'>
            <div className="form-group mb-6">
                <label className="block text-gray-700 text-sm font-bold mb-2">Name</label>
                <input 
                  className={classes} 
                  type="text"
                  id="name"
                  placeholder="Enter your name here...."
                  value={user_name}
                  name='user_name'
                  onChange={(e) => setName(e.target.value)}
                  required
                />
              </div>
            <div className="form-group mb-6">
                <label className="block text-gray-700 text-sm font-bold mb-2">Email</label>
                <input 
                  className={classes} 
                  type="text"
                  id="email"
                  placeholder="Enter your Email here...."
                  value={user_email}
                  name='user_email'
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>
            <div className="form-group mb-6">
                <label className="block text-gray-700 text-sm font-bold mb-2">Phone Number</label>
                <input 
                  className={classes} 
                  type="tel"
                  placeholder="Enter your Phone Number here...."
                  value={user_phone}
                  id="phone"
                  name='user_phone'
                  onChange={(e) => setPhone(e.target.value)}
                  required
                />
              </div>
            <div className="form-group mb-6">
                <label className="block text-gray-700 text-sm font-bold mb-2">Company</label>
                <input 
                  className={classes} 
                  type="text"
                  id='company'
                  placeholder="Enter your Company Name here...."
                  value={user_company}
                  name='user_company'
                  onChange={(e) => setCompany(e.target.value)}
                  required
                />
              </div>
            <div className="form-group mb-6 flex flex-col gap-3">
              {
                selectedServices.length > 0 && <div className='bg-[#37006718] px-3 py-4 h-auto w-full flex items-center flex-row flex-wrap gap-3'>
                {
                      selectedServices.map((service) => (
                        <h3 className='bg-custom-bg p-2 rounded-lg text-white' key={service}>{service}</h3>
                      ))
                    }
          </div>
              }
              
                <label className="block text-gray-700 text-sm font-bold mb-2">Select Services</label>
                {
                  services.map((service) => (
                    <>
                    <div key={service} className=''>
                        <label htmlFor={service.name} className='flex flex-row gap-3'>
                        <input 
                          className="text-gray-700 rounded border border-solid border-gray-300 focus:border-pink-600 focus:outline-none"
                          type="checkbox"
                          id={service.name}
                          value={service.name}
                          name='user_services'
                          checked={selectedServices.includes(service.name)}
                          onChange={handleServiceSelection}
                        />
                        <h6>
                          {service.name}
                        </h6>
                        </label>
                    </div>
                    </>
                  ))
                }
              </div>
              {
                loading ? <p className='bg-pink-300 text-black rounded-lg p-2'>Loading.....</p> : <NormalButton title="Submit" type="submit" />

              }
          </form>
          {
            showModal && (
              <PopupModal 
                message="Message sent succesfully"
                onClose={handleCloseModal}
                autoCloseDuration={15000}
              />
            )
          }
          {/* {
              sent && <p className='bg-pink-300 text-black p-4 rounded-md '>Message sent succesfully</p>
            } */}
        </div>
      </section>
    </main>
    <BackToTop />
    <Footer />
    </>
  )
}

export default Enquiry