import React from 'react'
import { unifiedCommunication } from '../../utils/constants'
import { BackToTop, Footer, Nav,  HeadingBox, TitleBox } from '../../components'
const UnifiedCommunication = () => {
  let even = []
  const number = [1,2,3,4,5,6,7,8,9,10]
  even = ['102','104','106','108','110']
  const numb = even.map((item) => item)
  const classes = "lg:px-10 px-4 py-12 flex lg:flex-row flex-col gap-5 justify-between"
  const evenClass = "lg:px-10 px-4 py-12 bg-[#37006718] flex lg:flex-row-reverse flex-col gap-5 justify-between"
  return (
    <>
    <Nav />
    <main>
        <section className="lg:py-20 py-12 lg:px-10 px-4 bg-[#FFE6D2] flex lg:flex-row flex-col  justify-between">
        <div>
          <HeadingBox
              title="Unified Communication"
              subtitle=""
              desc="We integrate voice, video, messaging, and conferencing tools into a single, seamless platform, enabling real-time communication and improving productivity across teams and locations"
          />
        </div>
        </section>
        {
          unifiedCommunication.map((item) => (
            <section className={even.includes(item.id) ? evenClass : classes} key={item.id}>
        <div className="flex">
          <TitleBox 
            title={item.title}
            desc={item.description}
          />
          </div>
            <div className="flex lg:w-[500px] w-full lg:h-[300px] h-[250px] relative">
                <img src={item.image} alt="Feature" className="h-full object-cover" /> 
            </div>
      </section>
          ))
        }
    </main>
    <BackToTop />
    <Footer />
    </>
  )
}

export default UnifiedCommunication