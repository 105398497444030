import HeadingBox from './HeadingBox'
import ProcessBox from './ProcessBox'
import { ourProcess } from "../utils/constants"
import TopAni from './TopAni'

const Process = () => {
  return (
    <section className='lg:px-10 px-4 py-20 flex flex-col gap-7'>
        <div className='flex flex-row justify-between'>
            <div>
                <HeadingBox
                    subtitle="OUR PROCESS"
                    title="The process we are working With Our client Worldwide"
                    desc="At Ventures Business Group, we follow a systematic and collaborative approach to deliver exceptional IT solutions. Our process encompasses the following key steps"
                />
            </div>
            <div className=''>
                <TopAni />
            </div>
        </div>
        <div className='grid lg:grid-cols-3 md:grid-cols-2 gap-5 '>
            {
                ourProcess.map((item) => (
                    <ProcessBox number={item.number} title={item.title} desc={item.description} bottom={item.bottom}
                    key={item.title}
                    />
                ))
            }
        </div>
    </section>
  )
}

export default Process