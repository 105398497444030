import { AnimatePresence, motion, useCycle } from "framer-motion";
import { hover, item, scale, tap, xitem, yitem } from "../utils/variants";
import CustomButton from "./CustomButton"
import { useEffect, useState } from "react";
import AniBar  from "./Anibar";
import { dese } from "../assets";
const texts = ["Advanced", 'Innovative', 'Tailored', 'Dynamic', 'Cutting-Edge'];
const Hero = () => {
  const [index, setIndex] = useState(0)
  const [open, cycleOpen] = useCycle(false, true)

  useEffect(() => {
    const interval = setInterval(() => {
      setIndex((index) => (index + 1) % texts.length)
    }, 2000);
    return () => clearInterval(interval);
  }, [])
  return (
    <div className="hero bg-[#FCFCFD] lg:px-10 px-4 lg:py-20 py-12 gap-10">
        <div className="flex-1 flex flex-col gap-2">
            {/* <motion.p className="font-sm" variants={item} initial="hidden" animate="visible">
              MEET VENTURES BIZ GROUP
            </motion.p> */}
            <motion.h1 className="hero__title" variants={item} initial="hidden" animate="visible">
                Unleash the power of - {" "}
                <AnimatePresence>
                  <motion.span
                    initial={{scale: 0}}
                    animate={{scale:1}}
                    exit={{scale:0}}
                  >
                    {texts[index]}
                  </motion.span>
                </AnimatePresence>
                {" "} IT Solutions
            </motion.h1>
            <motion.p variants={item} initial="hidden" animate="visible" className="lg:text-xl text-base font-normal lg:max-w-[622px] max-w-[100%]">
            Welcome to our world of innovation and transformation. At Ventures Business and Investment Limited, we specialize in providing top-notch IT solutions that elevate your business to new heights. 
            </motion.p>
            <CustomButton title="Request A Quote" routeLink="request" />
        </div>
        {/* <div className="hero__image-container"> */}
          <motion.div className="flex" variants={yitem} initial="hidden" animate="visible">
            <div className="relative  lg:w-[523px] w-[100%] lg:h-[500px] h-[400px]">
              <div className="absolute right-0 top-0 h-[222px] w-[24px] z-10">
                <AniBar />
              </div>
              <img
                src={dese}
                className="object-cover w-full h-full"
                alt="hero image"  
              />
              <div className="absolute bottom-0 left-0 h-[103px] w-[24px]">
                <AniBar />
              </div>
            </div>
          </motion.div>
        {/* </div> */}
    </div>
  )
}

export default Hero