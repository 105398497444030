import {term} from "../assets"

const ExpertiseBox = ({icon, title, description}) => {
  return (
    <div className="flex flex-row items-start gap-4 bg-[#37006718] p-4">
        <div className="bg-[#37006718] p-2 rounded-full w-[30px] h-[30px] flex justify-center items-center ">
            <img src={term} width={15} height={15} className="" alt="Delivery" />
        </div>
            
        <div className="">
            <h4 className="text-base text-black font-semibold">{title}</h4>
            <p className="text-sm text-[#5D5F6D] font-medium max-w-[500px]">{description}</p>
        </div>
    </div>
  )
}

export default ExpertiseBox